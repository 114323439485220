<template>
  <div class="list_wrap" @click="blurFun">
    <h1 class="h_none">专利检索</h1>
    <h2 class="h_none">蓝灯鱼专利检索,专利,专利检索,专利搜索,专利查询</h2>
    <div class="list_input_wrap">
      <div :class="['input-single',{'borderBlue':inputFocus}]">
        <!-- @click="globalEvent" -->
        <span @click="globalEvent"></span>
        <div class="input_box">
          <input class="input-con" autofocus v-model="search" @click="focusFun" @keyup.enter="onSearch"
            @focus="inputFocus=true" @blur="inputFocus=false" :placeholder="isAI ? $t(`PS_list.input_con[0]`) : $t(`PS_list.input_con[1]`)" />
<!--          <i :class="['patent_switch',{'defaultActive':!isAI}]" @click="switchEvent()">AI</i>-->
<!--          <i class="patent_close" v-if="search != ''" @click="closeEvent()">-->
<!--            <img src="@/assets/images/patent/icon_close.png" mode="widthFix" />-->
<!--          </i>-->
          <div class="add_keywords_wrap" v-show="addKeywordsShowBool">
            <div class="add_keywords_con" v-for="(item,index) in keywordsList" :key="index">
              <el-select class="add_keywords_select" v-model="item.condition" :placeholder="$t(`PS_list.add_keywords_select`)">
                <el-option v-for="(item,index) in conditionArr" :key="index" :label="item" :value="item"></el-option>
              </el-select>
              <el-select class="add_keywords_select" v-model="item.fieldName" :placeholder="$t(`PS_list.add_keywords_select`)"
                @change="literatureEvent(item,index)">
                <el-option-group v-for="group in literatureArr" :key="group.name" :label="group.name">
                  <el-option v-for="item in group.list" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-option-group>
              </el-select>
              <div class="add_keywords_input_wrap" v-show="item.type == 'input_keywords'" @click.stop>
                <el-input class="add_keywords_input add_keywords_title" v-model="item.fieldValue" clearable
                  placeholder="例如：手机 通讯"></el-input>
                <i class="search_keyword_btn" @click="keywordlDialogEvent(item.fieldValue,index)"></i>
              </div>
              <div class="add_keywords_input_wrap" v-show="item.type == 'input'" @click.stop>
                <el-input class="add_keywords_input" v-model="item.fieldValue" placeholder="例如：CN123456 CN234567"
                  clearable></el-input>
              </div>
              <el-date-picker class="add_keywords_date" v-show="item.type == 'date'" v-model="item.dateStart"
                value-format="yyyy-MM-dd" type="date" placeholder="开始日期"></el-date-picker>
              <em style="margin:0 5px;line-height:34px;" v-show="item.type == 'date'">-</em>
              <el-date-picker class="add_keywords_date" v-show="item.type == 'date'" v-model="item.dateEnd"
                value-format="yyyy-MM-dd" type="date" placeholder="结束日期"></el-date-picker>
              <i :class="['del_hurdles_btn',{'date_hurdles':item.type == 'date'}]"
                @click.stop="(keywordsList.length > 1) ? delHurdlesEvent(index) : ''"></i>
            </div>
            <p class="addHurdlesBtn" @click="addHurdlesEvent">+ 新增栏位</p>
          </div>
        </div>
        <!-- <p class="keyword_btn" @click="keywordlDialogVisible = true">关键词</p> -->
        <p class="query-btn" @click="onSearch"></p>
      </div>
    </div>
    <div class="list_main">
      <transition name="slide-left">
        <div class="list_left" v-show="leftShow">


          <div class="list_left_rela">
            <div class="list_left_arrow" @click="leftShow = !leftShow">《</div>
            <div class="keyword">
              <h6>关键词</h6>
              <p>
                <el-input type="text" v-model="newKeyword" placeholder="添加关键词" @keyup.native.enter="onAddKeyword">
                </el-input>
                <i @click="onAddKeyword">
                  <img src="@/assets/images/patent/add_icon.png" alt />
                </i>
              </p>
              <template v-if="keywordArr && keywordArr.length > 0">
                <!-- :class="{'el-tag-full':item.length>12}"   暂时未用到  masterKeyWords
slaveKeyWords
                followerKeyWord-->
                <el-tag v-if="masterKeyWords && masterKeyWords.length > 0" v-for="(item,index) in masterKeyWords"
                  :key="'master_'+index" closable :hit="false" :disable-transitions="true" size="mini"
                  @close="onDeleteKeyword(1,index)">{{replaceTag(item)}}</el-tag>
                <el-tag v-if="slaveKeyWords && slaveKeyWords.length > 0" v-for="(item,index) in slaveKeyWords"
                  :key="'slave_'+index" closable :hit="false" :disable-transitions="true" size="mini"
                  @close="onDeleteKeyword(2,index)">{{replaceTag(item)}}</el-tag>
                <el-tag v-if="followerKeyWords && followerKeyWords.length > 0" v-for="(item,index) in followerKeyWords"
                  :key="'follower_'+index" closable :hit="false" :disable-transitions="true" size="mini"
                  @close="onDeleteKeyword(3,index)">{{replaceTag(item)}}</el-tag>
              </template>
              <template v-else>
                <span class="no-data">{{$t(`PS_list.none`)}}</span>
              </template>
              <!-- <a href="javascript:void(0);" v-show="keywordArr.length > 5 && keywordArr.length > keywordShowLength"
              @click="keywordMoreEvent">更多></a>-->
            </div>

<!--            循环展示-->
            <div class="list_left_item" v-for="(item,index) in aggregations" :key="index">
              <h6 @click.stop="openEvent(item)">
                {{item.name}}
                <i :class="{'rotate':item.open}"></i>
              </h6>
<!--              不显示渲染-->
              <ul v-show="item.open">
<!--                给每一个绑定一个对象-->
                <template v-for="(itm,idx) in item.list" >
                  <el-checkbox v-if="itm.name == '全部'"  @change="handleCheckAllChange(item,$event)">全部</el-checkbox>
                </template>

                <el-checkbox-group v-model="checkAggregations[item.type]">
<!--                  结果里list数据为[]-->
                  <li v-for="(itm,idx) in item.list" :key="idx" v-if="idx < item.length && itm != ''">
                    <el-checkbox  v-if="itm.name != '全部'" :label="itm.name" :value="itm.id">{{replaceCheck(itm.name)}} {{itm.value?'( ':''}} {{itm.value}} {{itm.value?')':''}}
                    </el-checkbox>
                  </li>
                </el-checkbox-group>

<!--                显示更多的条件-->
                <li v-show="item.list.length > 5 && item.length < item.list.length"
                  @click="aggregationsMoreEvent(item,index)">
                  <a href="javascript:void(0);">更多></a>
                </li>
              </ul>
            </div>
          </div>

          <button @click="filterEvent">筛选</button>
        </div>
      </transition>




      <div class="list_middle" :style=" rightShow ? 'width:50%;' : 'width:100%'">
        <p class="slide_left_btn" @click="leftShow = !leftShow" v-show="!leftShow">》</p>
        <div class="list_con" style="width:100%">
          <div class="list_con_top" v-if="!emptyDataBool && records.length != 0">
            <p class="list_con_title"></p>
            <ul>
              <li @click="!rightShow ? dimensionExcelExportEvent() : ''" :class="{'active':rightShow}">

              </li>
              <el-tooltip content="生成分析报告" placement="bottom" effect="light">
                <li @click="!rightShow ? downloadReport() : ''" :class="{'active':rightShow}">
                  <img src="@/assets/images/patent/report_icon.png" alt />
                </li>
              </el-tooltip>
              <li @click="!rightShow ? listEvent() : ''" :class="{'active':rightShow}">
                <img v-show="listType == 'list'" src="@/assets/images/patent/list_icon.png" alt />
                <img v-show="listType == 'table'" src="@/assets/images/patent/list_active_icon.png" alt />
              </li>
              <li @click="!rightShow ? tableEvent() : ''" :class="{'active':rightShow}">
                <img v-show="listType == 'table'" src="@/assets/images/patent/table_icon.png" alt />
                <img v-show="listType == 'list'" src="@/assets/images/patent/table_active_icon.png" alt />
              </li>
            </ul>
          </div>
          <div :class="rightShow ? 'list_con_middle flex' : 'list_con_middle'">
            <!-- <p class="list_con_select_all">
              <el-checkbox v-model="checked">全选</el-checkbox>
            </p>-->
            <div class="list_con_middle_fixed" ref="listMiddFixed">
              <ul v-if="listType == 'list' && records.length != 0">
                <li v-for="(item,index) in records.slice((currentPage-1)*pageSize,currentPage*pageSize)" :key="index"
                  :class="{'active':item.pubNo == detailData.pubNo,'li1680':rightShow && leftShow}">
                  <!-- <b class="check">
                    <el-checkbox v-model="checked"></el-checkbox>
                  </b>-->
                  <i class="img" @click="toDetail(item.patentDocumentValueId)">
                    <img :src="item.imageUrl" alt />
                  </i>
                  <div class="des">
                    <div class="des_title" @click="toDetail(item.patentDocumentValueId)">
                      <em>{{indexMethod(index)}}.</em>
                      <h6 v-html="getHighlightTitle(item.highlightTitle)"></h6>
                      <b
                        :class="item.lawState == 'valid' ? 'effective' : item.lawState == 'review' ? 'trial' : item.lawState == 'invalid' ? 'invalid' : item.lawState == 'default' ? 'unknown' :''">{{item.lawState == 'valid' ? '有效' : item.lawState == 'review' ? '审核中' : item.lawState == 'invalid' ? '无效' : item.lawState == 'default' ? '未知' :''}}</b>
                    </div>
                    <div class="des_info">
                      <span>
                        公开号：
                        <strong @click.stop="goDetail(item.patentDocumentValueId)">{{item.pubNoAll}}</strong>
                      </span>
                      <span>
                        申请号：
                        <strong>{{item.applyNoAll}}</strong>
                      </span>
                      <br />
                      <span>
                        发明人：
                        <strong v-for="(item,index) in item.inventors" :key="index">{{item}}</strong>
                      </span>
                      <span>
                        申请人：
                        <strong v-for="(item,index) in item.applicants" :key="index">{{item}}</strong>
                      </span>
                      <span>
                        申请日期：
                        <strong>{{item.applyDate}}</strong>
                      </span>
                    </div>
                    <p class="fraction">
                      <span>专利度: {{item.patent}} </span>
                      <span>独权度: {{item.independence}} </span>
                      <span>方法度: {{item.method}} </span>
                      <span>特征度: {{item.feature}} </span>
                    </p>
                    <p class="des_con" v-html="getHighlightAbstract(item.highlightAbstract)"></p>
                    <p class="fraction">
                      <!-- 相关值: {{Math.floor(item.score)}} -->
                      <span class="rate">
                        <el-rate v-model="item.gradeScore" :colors="colors" @change="rateEvent(item,index)"></el-rate>
                      </span>
                    </p>
                  </div>
                </li>
              </ul>

              <div class="list_table" v-show="listType == 'table'">
                <el-table :data="records.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                  style="width:95%;margin:0 auto;" :row-class-name="tableRowClassName">
                  <el-table-column width="40" align="center">
                    <template slot-scope="scope">
                      <i @click.stop="toDetail(scope.row.patentDocumentValueId)">
                        <img src="@/assets/images/patent/see_icon.png" alt />
                      </i>
                    </template>
                  </el-table-column>
                  <el-table-column type="index" :index="indexMethod" label="序号" align="center"></el-table-column>
                  <el-table-column prop="pubNoAll" label="公开号" align="center"></el-table-column>
                  <el-table-column prop="title" label="标题" align="center">
                    <template slot-scope="scope">
                      <span v-html="getHighlightTitle(scope.row.title)"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="pubDate" label="公开日" align="center"></el-table-column>
                  <el-table-column prop="applyNoAll" label="申请号" align="center"></el-table-column>
                  <el-table-column prop="applyDate" label="申请日" align="center"></el-table-column>
                  <el-table-column prop="lawState" label="法律状态" align="center">
                    <template
                      slot-scope="scope">{{scope.row.lawState == 'valid' ? '有效' : scope.row.lawState == 'review' ? '审核中' : scope.row.lawState == 'invalid' ? '无效' : scope.row.lawState == 'default' ? '未知' :''}}</template>
                  </el-table-column>
                  <el-table-column prop="score" label="相关度" align="center">
                    <template slot-scope="scope">{{Math.round(scope.row.score)}}</template>
                  </el-table-column>
                </el-table>
              </div>
              <section class="no-result" v-show="!emptyDataBool && records.length == 0">
                <i>
                  <img src="@/assets/images/common/nodata.png" class />
                </i>
                <p>自救中，暂无结果</p>
              </section>
            </div>
            <div class="list_con_bottom" v-show="!emptyDataBool && records.length > 0">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                :pager-count="pagerCount" layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <transition name="slide-right">
        <div class="list_right" v-show="rightShow">
          <div class="list_right_top">
            <p>
              <i class="left_btn" @click="listRightBtn">
                <img src="@/assets/images/patent/left.png" alt />
              </i>
<!--              <i class="pdf_btn" @click="pdfEvent(detailData.pdfUrl,detailData.ucid)">-->
<!--                <img src="@/assets/images/common/download.png" alt />-->
<!--              </i>-->
            </p>
            <i class="enlarge_btn" @click="goDetail(detailData.patentDocumentValueId)">
              <img src="@/assets/images/patent/enlarge_icon.png" alt />
            </i>
          </div>
          <div class="list_rigth_title">
            <strong>{{detailData.pubNoAll}}</strong>
            <span
              :class="detailData.lawState == 'valid' ? 'effective' : detailData.lawState == 'review' ? 'trial' : detailData.lawState == 'invalid' ? 'invalid' : detailData.lawState == 'default' ? 'unknown' :''">{{detailData.lawState == 'valid' ? '有效' : detailData.lawState == 'review' ? '审核中' : detailData.lawState == 'invalid' ? '无效' : detailData.lawState == 'default' ? '未知' :''}}</span>
            <h6 v-html="getHighlightTitle(detailData.title)"></h6>
          </div>
          <ul class="list_right_tab">
            <li :class="currentDetailIndex == index ? 'active' : ''" v-for="(item,index) in detailTab" :key="index"
              @click="detailTabEvent(index)">{{item.name}}</li>
          </ul>
          <ul class="list_right_tab_con">
            <li v-show="currentDetailIndex == 0">
              <p>
                <span>
                  公开号：
                  <strong>{{detailData.pubNoAll}}</strong>
                </span>
                <span>
                  公开/公告日：
                  <strong>{{detailData.pubDate}}</strong>
                </span>

                <span>
                  申请号：
                  <strong>{{detailData.applyNoAll}}</strong>
                </span>
                <span>
                  申请日期：
                  <strong>{{detailData.applyDate}}</strong>
                </span>

                <span>
                  申请人：
                  <strong v-for="(item,index) in detailData.applicants" :key="index">{{item}}</strong>
                </span>
                <span>
                  发明人：
                  <strong v-for="(item,index) in detailData.inventors" :key="index">{{item}}</strong>
                </span>

                <span>
                  专利类型：
                  <strong>{{detailData.kind}}</strong>
                </span>
                <span v-if="detailData.mainIpcs && detailData.mainIpcs.length != 0">
                  IPC分类号：
                  <strong v-for="(item,index) in detailData.mainIpcs" :key="index">{{item}}</strong>
                </span>

                <span>
                  优先权国家：
                  <strong v-for="(item,index) in detailData.patentPriorityClaimsVos"
                    :key="index">{{item.country}}</strong>
                </span>
                <span>
                  优先权日：
                  <strong v-for="(item,index) in detailData.patentPriorityClaimsVos" :key="index">{{item.date}}</strong>
                </span>
              </p>
              <h6>摘要</h6>
              <p v-html="detailData.abstractInfo"></p>
            </li>
            <li v-show="currentDetailIndex == 1">
              <h6>权利要求</h6>
              <p class="list_right_height" v-html="detailData.claim"></p>
            </li>
            <li v-show="currentDetailIndex == 2">
              <h6>说明书</h6>
              <p class="list_right_height" v-html="detailData.instruction"></p>
            </li>
            <li v-show="currentDetailIndex == 3">
              <p class="list_right_height">
                <img onerror="this.classList.add('error')" v-for="(item,index) in detailData.figureImages" :key="index" :src="item" alt
                  @click="dialogSwiperVisible = true" />
              </p>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <!-- 全球数据弹窗 -->
    <el-dialog :visible.sync="globalDialogVisible" :close-on-click-modal="false" width="500px"
      custom-class="global_dialog">
      <global :globalData="globalDialogVisible" @globalparamEvent="getGlobalParam"></global>
    </el-dialog>
    <!-- 关键词弹窗 -->
    <el-dialog :visible.sync="keywordlDialogVisible" :close-on-click-modal="false" width="654px"
      custom-class="keyword_dialog">
      <keyword @getKeywordsParam="getKeyword" @getCancelKeyword="getCancel" :keywordData="keywordlDialogVisible">
      </keyword>
    </el-dialog>
    <!-- 轮播图 -->
    <el-dialog :visible.sync="dialogSwiperVisible" custom-class="swiper_dialog" width="60%">
      <div class="swiper-box" style="background:#fff;" v-if="dialogSwiperVisible">
        <swiper :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="(item,index) in detailData.figureImages" :key="item">
            <img :src="item" />
          </swiper-slide>
          <!-- 左右箭头 -->
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
          <!-- 分页器 -->
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- <div class="swiper_right"
        style="margin-left:100px;width:190px;height:500px;background:transparent;display:inline-block;">
        <swiper :options="swiperOptionThumbs" ref="swiperThumbs">
          <swiper-slide class="swiper-slide" v-for="(item,index) in imageArr" :key="index">
            <img :src="item" />
          </swiper-slide>
        </swiper>
      </div>-->
    </el-dialog>
  </div>
</template>
<script>
let that;
import global from "@/components/patentPart/global";
import addHurdles from "@/components/patentPart/addHurdles";
import keyword from "@/components/patentPart/keyword";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "patentSearchList",
  data () {
    return {
      inputFocus: false,
      isAI: true, //是否是AI检索
      db: ["CN"], //选择的数据库
      patentType: ["A,B,C", "U,Y", "S"], //选择的专利类型
      conditionArr: ["AND", "OR", "NOT"],
      literatureArr: this.$t(`PS_list.literatureArr`),
      keywordsList: [
        {
          condition: "AND",
          fieldName: "not_cut_word",
          type: "input_keywords", // input  输入框   date  日期框
          fieldValue: "",
          dateStart: "",
          dateEnd: ""
        }
      ],
      keywordsIndex: 0,
      keywordsValue: "",
      search: "",
      //   全球数据弹窗是否展示
      globalDialogVisible: false,
      //   全球选中数据
      globalDataArr: [],
      //   添加关键词列表是否显示
      addKeywordsShowBool: false,
      leftShow: true,
      rightShow: false,
      total: 0,
      pageSize: 30,
      currentPage: 1,
      pagerCount: 11,
      //   详情tab切换
      detailTab: this.$t(`PS_list.detailTab`),
      currentDetailIndex: 0,
      //   关键词数组
      keywordArr: [],
      //   主关键词
      masterKeyWords: [],
      //   从关键词
      slaveKeyWords: [],
      // 其他关键词
      followerKeyWords: [],
      //   关键词展示长度
      keywordShowLength: 5,
      //   添加关键词
      newKeyword: "",
      //   筛选条件展示集合
      aggregations: [],
      //   筛选条件选中集合
      checkAggregations: [],
      // 点击筛选按钮次数
      filterNum: 0,
      //   专利总条数
      searchPatentTotal: 0,
      //   列表数据
      records: [],
      // 查询类型
      searchType: 1,
      // 分值颜色
      colors: ["#ff9f2d", "#ff9f2d", "#ff9f2d"],
      listType: "list",
      detailData: {},
      //   关键词弹窗是否显示
      keywordlDialogVisible: false,
      //   为空数据是否显示
      emptyDataBool: true,
      //   轮播图是否显示
      dialogSwiperVisible: false,
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
        //开启循环模式
        // loop: true
      },
      // 获取维度传的值
      appNoDTOs: [],
      // 获取分数传的值
      gradeScoreArr: [],
      //高级检索
      beforsearch: '',
      //专利类型
      kinds:[],
      //需要替换的名称
      replace:['申请号','申请日','公开号','公开日','标题/摘要','法律状态','申请人','发明人','代理机构','代理人','IPC分类','公开','有效','无效','审中','全部'],
      //已替换
      replaced:['APNO','APD','PN','PBD','TA','LS','AN','IN','ATC','AT','IPC','review','valid','invalid','default','all'],
      type:'undefined'
      //   swiperOptionThumbs: {
      //     spaceBetween: 10,
      //     slidesPerView: 4,
      //     watchSlidesVisibility: true,
      //     direction: 'vertical',
      //
      //   }
      //
    };
  },
  components: {
    global,
    addHurdles,
    keyword
  },
  computed: {
    ...mapState(["me", "noticeList"])
  },
  beforeCreate () {
    that = this;
  },
  mounted () {
    var _this = this;
    if(localStorage.getItem("beforsearch")){
      _this.beforsearch = localStorage.getItem("beforsearch");
    }

    _this.search = localStorage.getItem("search");
    //获取type
    _this.type = localStorage.getItem("type");

    _this.isAI = localStorage.getItem("isAI") === "false" ? false : true;
    _this.keywordsList = JSON.parse(localStorage.getItem("keywordsList"));
    this.Recapture()
    setTimeout(() => {
      //
      if (this.isAI) {
        if (this.search != "") {
          this.getIsLoginAJax(1);
        }
      } else {
        this.getIsLoginAJax(1);
      }
    }, 300);
  },
  methods: {
    ...mapMutations(["setMe", "setNoticeList", "loadingBool"]),
    //重新获取全球数据库
    Recapture(){
      var _this = this;
      //专利类型获取
      _this.kinds = JSON.parse(localStorage.getItem("kinds"));
      _this.db =
        _this.tool.getCookie("country") != undefined &&
        _this.tool.getCookie("country") != ""
          ? _this.tool.getCookie("country").split(";")
          : ["CN"];
      _this.patentType =
        _this.tool.getCookie("kinds") != undefined &&
        _this.tool.getCookie("kinds") != ""
          ? _this.tool.getCookie("kinds").split(";")
          : ["A,B,C", "U,Y"];
    },
    //是否全选
    handleCheckAllChange(data,e){

      if(e){
        //选择全部
        for(let i = 0 ; i < data.list.length ; i++){

          if(!this.checkAggregations[data.type].includes(data.list[i])){
            this.checkAggregations[data.type].push(data.list[i].name)
          }
        }
      }else {
        //取消全部
        this.checkAggregations[data.type] = []
      }

    },
    focusFun () {
      if (!this.isAI) {
        this.addKeywordsShowBool = true;
      }
    },
    blurFun (event) {
      var sp = document.getElementsByClassName("add_keywords_wrap")[0],
        inputCon = document.getElementsByClassName("input-con")[0],
        keywordDialog = document.getElementsByClassName("keyword_dialog")[0];
      if (sp && inputCon && keywordDialog) {
        if (
          !sp.contains(event.target) &&
          !inputCon.contains(event.target) &&
          !keywordDialog.contains(event.target)
        ) {
          this.addKeywordsShowBool = false;
        }
      }
    },
    // 点击全球按钮
    globalEvent () {
      this.globalDialogVisible = true;
    },
    // 获取选中全球数据
    getGlobalParam (data) {
      Object.keys(data).forEach(k => {
        if (k == "country") {
          this.db = data[k];
        }
        if (k == "kinds") {
          this.patentType = data[k];
        }
      });
      this.globalDialogVisible = false;
      this.Recapture()
    },
    addKeywordsShowEvent () {
      this.addKeywordsShowBool = !this.addKeywordsShowBool;
    },
    literatureEvent (val, ide) {
      this.literatureArr.forEach((item, index) => {
        item.list.forEach((itm, idx) => {
          if (itm.value == val.fieldName) {
            this.keywordsList[ide].type = item.type;
          }
        });
      });
    },
    closeEvent () {
      this.search = "";
    },
    switchEvent () {
      this.isAI = !this.isAI;
    },
    // 新增栏位
    addHurdlesEvent () {
      var obj = {
        condition: "AND",
        fieldName: "not_cut_word",
        type: "input_keywords", // input  输入框   date  日期框
        fieldValue: "",
        dateStart: "",
        dateEnd: ""
      };
      this.keywordsList.push(obj);
    },
    // 关键词弹窗显示
    keywordlDialogEvent (valueParam, indexParam) {
      //   this.keywordsValue = valueParam;
      this.keywordsIndex = indexParam;
      this.keywordlDialogVisible = true;
    },
    // 获取关键词
    getKeyword (data) {
      //   this.search = this.search + " 【 " + data.join(" ") + " 】";
      this.keywordsList[this.keywordsIndex].fieldValue =
        this.keywordsList[this.keywordsIndex].fieldValue + " " + data.join(" ");
      this.keywordlDialogVisible = false;
    },
    // 删除栏位
    delHurdlesEvent (index) {
      this.keywordsList.splice(index, 1);
    },
    getCancel (data) {
      this.keywordlDialogVisible = data;
    },
    // 验证蓝灯鱼用户是否登录
    async getIsLoginAJax (val) {
      // let data = await this.$axios.post("patent/search/isLogin");
      let data = this.tool.getCookie('auth')
      if (data) {
        if (this.db.length == 0) {
          this.$message("请至少选择一个数据库");
          return;
        } else {
          // val   1  顶部搜索按钮    2  左侧搜索按钮    3  分页按钮
          this.keywordsList = JSON.parse(localStorage.getItem("keywordsList"));
          this.search = localStorage.getItem("search");

          if (this.keywordsList != "" || this.keywordsList != null) {
            for (var i = 0; i < this.keywordsList.length; i++) {
              if (
                this.keywordsList[i].fieldValue ||
                this.keywordsList[i].dateStart != "" ||
                this.keywordsList[i].dateEnd != "" ||
                this.search != ""
              ) {
                this.searchPatentAjax(val);
                return;
              }
            }
          } else {
            this.searchPatentAjax(val);
          }
        }
      }
      // else if (data.data.code == 1) {
      //   if (data.data.data.isLogin == "false") {
      //     this.$message({
      //       message: data.data.msg
      //     });
      //     this.setMe("");
      //     this.setNoticeList("");
      //   }
      // }
      else {

      }
    },
    onSearch () {
      let  advancedSearch = ''
      localStorage.setItem("isAI", this.isAI);
      localStorage.setItem("keywordsList", JSON.stringify(this.keywordsList));
      localStorage.setItem("search", this.search);

      //如果是高级检索
      if(localStorage.getItem("beforsearch")){
        advancedSearch = this.search
        for(let i = 0 ; i < this.replace.length ; i++){
          // string.replace(new RegExp(reallyDo, 'g'), replaceWith);
          advancedSearch = advancedSearch.replace(new RegExp(this.replace[i], 'g'), this.replaced[i])
        }
        this.beforsearch = advancedSearch
      }


      this.searchType = 1;
      this.filterNum = 0;
      if (this.isAI) {
        if (this.search != "") {
          this.getIsLoginAJax(1);
        }
      } else {
        this.getIsLoginAJax(1);
      }
      this.addKeywordsShowBool = false;
    },
    getHighlightTitle: function (data) {
      if (data) {
        // 一种TiO sub &gt; 2  &lt;/sub&gt;  纳米颗粒/TiO  &lt;sub&gt;   2  &lt;/sub&gt;  纳米管阵列及其应用

        var regLt = new RegExp("&lt;", "g"),
          regGt = new RegExp("&gt;", "g");

        var title = data.replace(regLt, "<").replace(regGt, ">");
        return title;
      } else {
        return "";
      }
    },
    getHighlightAbstract: function (data) {
      if (data) {
        var regBr = new RegExp("<br/>", "g");
        var abstract = data.replace(regBr, "");
        if (!this.isAI) {
          if (this.keywordArr) {
            this.keywordArr.forEach((im, index) => {
              abstract = abstract
                .split(im)
                .join(
                  '<span style="color:#ff9f2d;display:inline-block;">' +
                  im +
                  "</span>"
                );
            });
          }
          return abstract;
        } else {
          return abstract;
        }
      } else {
        return "";
      }
    },
    // 打分事件
    rateEvent (rateParam, indexParam) {
      var attr = that.search;
      // var regex = /\【(.+?)\】/g;
      var regex = new RegExp("\\【(.+?)】", "g");
      var arr1 = attr.match(regex);
      var arr2 = "",
        notCutKeyWordArr = [],
        str2 = [],
        notCutKeyWord = "";
      if (arr1 != null) {
        for (var i = 0; i < arr1.length; i++) {
          attr = attr.replace(arr1[i], "|");
          notCutKeyWordArr.push(arr1[i].replace("【", " "));
        }
        for (var k = 0; k < notCutKeyWordArr.length; k++) {
          str2.push(notCutKeyWordArr[k].replace("】", " "));
        }
        notCutKeyWord = str2.join(" ").trim();
        arr2 = attr
          .split("|")
          .join(" ")
          .trim();
      } else {
        arr2 = that.search;
        notCutKeyWord = "";
      }
      this.$axios.post("/lantern/langraderecord/saveGradeRecord", {
        aiMark: localStorage.getItem("isAI") === "false" ? "" : "AI",
        appNo: rateParam.applyNo,
        score: rateParam.gradeScore,
        gradeId: rateParam.gradeId,
        rankSort: this.indexMethod(indexParam),
        keyWord: arr2,
        patentConditions: this.isAI ? [] : this.keywordsList,
        masterKeyWords: this.masterKeyWords,
        slaveKeyWords: this.slaveKeyWords,
        followerKeyWords: this.followerKeyWords,
        searchType: this.searchType
      });
    },
    // 导出专利度等维度数据
    async dimensionExcelExportEvent () {
      var pubNos = [];
      this.records.forEach((item, index) => {
        pubNos.push(item.pubNoAll)
      })
      let data = await this.$axios.post(
        "/patent/excel/dimensionExcelExport",
        {
          appNos: pubNos
        },
        {
          responseType: "arraybuffer"
        }
      )

      let blob = new Blob([data.data], {
        type: `application/vnd.ms-excel'` //word文档为msword,pdf文档为pdf xls vnd.ms-excel'
      });
      let objectUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      let fname = `蓝灯鱼专利结果统计.xls`; //下载文件的名字
      link.href = objectUrl;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
    },
    // 专利度等维度数据
    getDimension () {
      this.$axios
        .post('/patent/search/dimension', {
          appNoDTOs: this.appNoDTOs
        })
        .then(res => {
          if (res.data.code == 0) {
            //             //独权度
            // private Integer independence;
            // //方法度
            // private Integer method;
            // //专利度
            // private Integer patent;
            // //特征度
            // private Integer feature;

            res.data.data.forEach((item, index) => {
              this.records
                .slice(
                  (this.currentPage - 1) * this.pageSize,
                  this.currentPage * this.pageSize
                )
                .forEach((itm, idx) => {
                  if (index == idx) {
                    this.$set(itm, "independence", item.independence);
                    this.$set(itm, "method", item.method);
                    this.$set(itm, "patent", item.patent);
                    this.$set(itm, "feature", item.feature);
                  }
                });
            })
          }
        });
    },
    // 获取分数值
    getGradeRecord () {
      var that = this;
      var attr = that.search;
      // var regex = /\【(.+?)\】/g;
      var regex = new RegExp("\\【(.+?)】", "g");
      var arr1 = attr.match(regex);
      var arr2 = "",
        notCutKeyWordArr = [],
        str2 = [],
        notCutKeyWord = "";
      if (arr1 != null) {
        for (var i = 0; i < arr1.length; i++) {
          attr = attr.replace(arr1[i], "|");
          notCutKeyWordArr.push(arr1[i].replace("【", " "));
        }
        for (var k = 0; k < notCutKeyWordArr.length; k++) {
          str2.push(notCutKeyWordArr[k].replace("】", " "));
        }
        notCutKeyWord = str2.join(" ").trim();
        arr2 = attr
          .split("|")
          .join(" ")
          .trim();
      } else {
        arr2 = that.search;
        notCutKeyWord = "";
      }
      that.$axios
        .post("/patent/graderecord/gradeRecord", {
          aiMark: this.beforsearch?'SUPER':this.isAI ? "AI" : "",
          appNoInfos: that.gradeScoreArr,
          keyWord: arr2,
          patentConditions: that.isAI ? [] : that.keywordsList,
          masterKeyWords: that.masterKeyWords,
          slaveKeyWords: that.slaveKeyWords,
          followerKeyWords: that.followerKeyWords
        })
        .then(res => {
          if (res.data.code == 0) {
            res.data.data.forEach((item, index) => {
              this.records
                .slice(
                  (this.currentPage - 1) * this.pageSize,
                  this.currentPage * this.pageSize
                )
                .forEach((itm, idx) => {
                  if (index == idx) {
                    this.$set(itm, "gradeScore", Number(item.score));
                    if (item.isGraded == 1) {
                      //isGraded  0 没有打过分 1打过分
                      this.$set(itm, "gradeId", item.gradeId);
                    }
                  }
                });
            })
          }
        });
    },
    lawfun(){
      if(this.checkAggregations["lawState"].includes('全部')){
        return ['invalid',"valid","default"]
      }else {
        return this.checkAggregations["lawState"].map((item) => {
          if(item == '无效'){
            return "invalid";
          }else if(item == '有效'){
            return 'valid';
          }else if(item == '未确认'){
            return 'default';
          }
        })
      }
    },
    //  专利检索展示结果接口
    async searchPatentAjax (val) {
      if (this.records.length == 0) {
        this.emptyDataBool = true;
      }
      // this.$nuxt.$loading.start();
      if (val != 3) {
        this.currentPage = 1;
      }
      this.searchPatent(val);
    },
    async searchPatent (val) {
      var attr = this.search;
      // var regex = /\【(.+?)\】/g;
      var regex = new RegExp("\\【(.+?)】", "g");
      var arr1 = attr.match(regex);
      var arr2 = "",
        notCutKeyWordArr = [],
        str2 = [],
        notCutKeyWord = "";
      if (arr1 != null) {
        for (var i = 0; i < arr1.length; i++) {
          attr = attr.replace(arr1[i], "|");
          notCutKeyWordArr.push(arr1[i].replace("【", " "));
        }
        for (var k = 0; k < notCutKeyWordArr.length; k++) {
          str2.push(notCutKeyWordArr[k].replace("】", " "));
        }
        notCutKeyWord = str2.join(" ").trim();
        arr2 = attr
          .split("|")
          .join(" ")
          .trim();
      } else {
        arr2 = this.search.trim();
        notCutKeyWord = "";
      }
      this.rightShow = false;
      this.detailData = {};
      // var regex1 = /(?<=【)[^】]+/g;
      let data = await this.$axios.post("/patent/search/searchPatent", {
        type: this.type === 'undefined'? undefined : this.type,
        aiMark: this.beforsearch?'SUPER':this.isAI ? "AI" : "",
        keyWord: arr2.trim(),
        // notCutKeyWord: notCutKeyWord,
        country: "CN",
        patentConditions: this.isAI ? [] : this.keywordsList,
        masterKeyWords: val == "1" ? [] : this.masterKeyWords,
        slaveKeyWords: val == "1" ? [] : this.slaveKeyWords,
        followerKeyWords: val == "1" ? [] : this.followerKeyWords,
        appDates: val == "1" ? [] : this.checkAggregations["appDate"],
        pubDates: val == "1" ? [] : this.checkAggregations["pubDate"],
        agents: val == "1" ? [] : this.checkAggregations["agents"],
        applicants: val == "1" ? [] : this.checkAggregations["applicants"],
        inventors: val == "1" ? [] : this.checkAggregations["inventors"],
        //法律状态
        // lawState: this.checkAggregations["lawState"],
        lawState: val == "1" ? [] : this.lawfun(),
        searchType: val == "1" ? 1 : 2, //searchType 检索类型： 1是查询 2是筛选
        pageSize: 200,
        kinds: this.patentType, //专利类型
        queryExpr: this.beforsearch
      });
      if (data.data.code == 0) {
        this.appNoDTOs = [];
        this.gradeScoreArr = [];
        this.$refs.listMiddFixed.scrollTop = 0;
        this.keywordArr = data.data.data.masterKeyWords.concat(
          data.data.data.slaveKeyWords,
          data.data.data.followerKeyWords
        );
        this.masterKeyWords = data.data.data.masterKeyWords;
        this.slaveKeyWords = data.data.data.slaveKeyWords;
        this.followerKeyWords = data.data.data.followerKeyWords;
        // 筛选条件重新赋值
        if (this.currentPage == 1 && val == 1) {
          if (data.data.data.aggregations.length != 0) {
            data.data.data.aggregations.forEach((item, index) => {
              this.$set(this.checkAggregations, item.type, []);
            });
            this.aggregations = data.data.data.aggregations;
          }
        }
        this.searchPatentTotal = data.data.data.records.length;
        this.records = data.data.data.records;


        data.data.data.records
          .slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
          )
          .forEach((item, index) => {
            this.appNoDTOs.push({
                lang: item.lang,
                publicationId: item.publicationId
            })
            this.gradeScoreArr.push({
              appNo: item.applyNo,
              rankSort: this.indexMethod(index)
            })
          });
        if(this.records != 0){
          this.getGradeRecord();
          this.getDimension()
        }

        if (this.records.length == 0) {
          this.rightShow = false;
          this.detailData = {};
        }
        this.total = data.data.data.records.length;
        // this.currentPage = data.data.data.current;
        // this.$nuxt.$loading.finish();
        this.emptyDataBool = false;
      }
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.gradeScoreArr = [];
      this.appNoDTOs = [];
      this.records
        .slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        )
        .forEach((item, index) => {
          this.gradeScoreArr.push({
            appNo: item.applyNo,
            rankSort: this.indexMethod(index)
          })
          this.appNoDTOs.push({
                lang: item.lang,
                publicationId: item.publicationId
            })
        });
        this.getGradeRecord();
        this.getDimension()
      //   this.searchPatentAjax(3);
    },
    handleCurrentChange (val) {
      this.$refs.listMiddFixed.scrollTop = 0;
      this.currentPage = val;
      this.gradeScoreArr = [];
      this.appNoDTOs = [];
      this.records
        .slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize
        )
        .forEach((item, index) => {
          this.gradeScoreArr.push({
            appNo: item.applyNo,
            rankSort: this.indexMethod(index)
          })
          this.appNoDTOs.push({
                lang: item.lang,
                publicationId: item.publicationId
            })
        });
        this.getGradeRecord();
        this.getDimension()
      //   this.searchPatentAjax(3)
    },
    //  截断超长的标签
    replaceTag (tag) {
      if (tag && tag.length > 12) {
        return tag.slice(0, 12) + " ...";
      } else {
        return tag;
      }
    },
    //  截断超长的标签
    replaceCheck (tag) {
      if (tag && tag.length > 6) {
        return tag.slice(0, 6) + " ...";
      } else {
        return tag;
      }
    },
    // 删除关键词
    onDeleteKeyword (keywordGrada, keyword) {
      // keywordGrada 主  1  从2   其他 3
      if (keywordGrada == 1) {
        this.masterKeyWords.splice(keyword, 1);
        this.masterKeyWords = [...this.masterKeyWords];
      } else if (keywordGrada == 2) {
        this.slaveKeyWords.splice(keyword, 1);
        this.slaveKeyWords = [...this.slaveKeyWords];
      } else if (keywordGrada == 3) {
        this.followerKeyWords.splice(keyword, 1);
        this.followerKeyWords = [...this.followerKeyWords];
      }
      this.keywordArr = this.masterKeyWords.concat(
        this.slaveKeyWords,
        this.followerKeyWords
      );
    },
    //    新增关键词
    onAddKeyword () {
      if (this.newKeyword) {
        if (!this.masterKeyWords.includes(this.newKeyword)) {
          this.masterKeyWords.unshift(this.newKeyword);
          this.newKeyword = "";
          this.keywordArr = this.masterKeyWords.concat(
            this.slaveKeyWords,
            this.followerKeyWords
          );
        } else {
          this.$message("关键词重复");
        }
      }
    },
    // 打开左侧leftmain菜单
    async openEvent (dataParam) {
      /*
          length: 5
          list: []
          name: "法律状态"
          open: false
          type: "lawState"
       */
      // 专利检索聚合查询接口：/patent/search/searchAggregation
      // 请求方式：POST
      // 参数列表和专利查询接口参数列表一样
      // 多加一个字段：aggregationName
      // "agents" -- "代理机构"
      // "applicants" -- "申请人"
      // "inventors" -- "发明人"
      // "appDate" -- "申请年度"
      // "pubDate" -- "公开年度"
      // "lawState" -- "法律状态"
      dataParam.open = !dataParam.open;
      this.$set(dataParam, "open", dataParam.open);
      if (dataParam.open == true && dataParam.list.length == 0) {
        var attr = this.search;
        // var regex = /\【(.+?)\】/g;
        var regex = new RegExp("\\【(.+?)】", "g");
        var arr1 = attr.match(regex);
        var arr2 = "",
          notCutKeyWordArr = [],
          str2 = [],
          notCutKeyWord = "";
        if (arr1 != null) {
          for (var i = 0; i < arr1.length; i++) {
            attr = attr.replace(arr1[i], "|");
            notCutKeyWordArr.push(arr1[i].replace("【", " "));
          }
          for (var k = 0; k < notCutKeyWordArr.length; k++) {
            str2.push(notCutKeyWordArr[k].replace("】", " "));
          }
          notCutKeyWord = str2.join(" ").trim();
          arr2 = attr
            .split("|")
            .join(" ")
            .trim();
        } else {
          arr2 = this.search;
          notCutKeyWord = "";
        }
        let data = await this.$axios.post("/patent/search/searchAggregation", {
          aiMark: this.beforsearch?'SUPER':this.isAI ? "AI" : "",
          keyWord: arr2,
          country: "CN",
          kinds: this.patentType, //专利类型
          queryExpr: this.beforsearch? this.beforsearch : "",
          patentConditions: this.isAI ? [] : this.keywordsList,
          masterKeyWords: this.searchType == "1" ? [] : this.masterKeyWords,
          slaveKeyWords: this.searchType == "1" ? [] : this.slaveKeyWords,
          followerKeyWords: this.searchType == "1" ? [] : this.followerKeyWords,
          searchType: this.searchType, //searchType 检索类型： 1是查询 2是筛选
          aggregationName: dataParam.type,
          pageSize: 200
        });
        if (data.data.code == 0) {
          if(dataParam.type == 'lawState'){
            //添加全部选择
            data.data.data.unshift({
              id: "9keyuezvtz3",
              name: "全部"
            })
            data.data.data.map(item => {
              if(item.name == 'invalid'){
                item.name = '无效'
              }else if(item.name == 'valid'){
                item.name = '有效'
              }else if(item.name == 'default'){
                item.name = '未确认'
              }
              return item
            })
          }
          this.$set(dataParam, "list", data.data.data);
        }
      }

    },
    // 关键词更多事件
    keywordMoreEvent () {
      this.keywordShowLength += 5;
    },
    // 更多事件
    aggregationsMoreEvent (param, index) {
      param.length += 5;
      this.$set(param, "length", param.length);
    },
    // 筛选按钮事件
    filterEvent () {

      if (this.isAI) {
        ++this.filterNum;
      } else {
        this.filterNum = 0;
      }

      this.searchType = 2;
      this.addKeywordsShowBool = false;
      localStorage.setItem("isAI", this.isAI);
      this.getIsLoginAJax(2);
    },
    toDetail (id) {
      this.detailAjax(id);
      if (this.rightShow) {
        return;
      }
      this.rightShow = !this.rightShow;
    },
    // 获取详情
    async detailAjax (id) {
      this.loadingBool(true);
      let data = await this.$axios.get("/patent/search/searchPatent/" + id);
      if (data.data.code == 0) {
        this.detailData = data.data.data;
      }
      this.loadingBool(false);
    },
    detailTabEvent (index) {
      if (index == this.currentDetailIndex) {
        return;
      }
      this.currentDetailIndex = index;
    },
    // 下载专利分析报告
    async downloadReport () {
      let publicationIds = [];
      this.records.slice(0, 10).forEach((item, index) => {
        publicationIds.push(item.publicationId)
      });
      let data = await this.$axios.post(
        "/patent/download/searchPatentReport",
        {
          aiMark: this.isAI ? "AI" : "",
          keyWord: this.search,
          country: "CN",
          kinds: this.patentType,
          patentConditions: this.keywordsList,
          allKeyWords: this.masterKeyWords.concat(
            this.slaveKeyWords,
            this.followerKeyWords
          ),
          appDates: this.checkAggregations["appDate"],
          pubDates: this.checkAggregations["pubDate"],
          agents: this.checkAggregations["agents"],
          applicants: this.checkAggregations["applicants"],
          inventors: this.checkAggregations["inventors"],
          //法律状态
          lawState: this.lawfun(),
          pageSize: 200,
          publicationIds: publicationIds,
          searchType: this.searchType
        },
        {
          responseType: "arraybuffer"
        }
      );
      let blob = new Blob([data.data], {
        type: `application/msword` //word文档为msword,pdf文档为pdf
      });
      let objectUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      let fname = `专利分析报告.docx`; //下载文件的名字
      link.href = objectUrl;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
    },
    tableEvent () {
      this.listType = "list";
    },
    listEvent () {
      this.listType = "table";
    },
    // table 索引方法
    indexMethod (index) {
      return (this.currentPage - 1) * this.pageSize + (index + 1);
    },
    // 表格设置高亮
    tableRowClassName ({ row }) {
      if (row.pubNo == this.detailData.pubNo) {
        return "selected-row";
      }
      return "";
    },
    listRightBtn () {
      this.rightShow = false;
      this.detailData = {};
    },
    goDetail (paramId) {
      // let newpage = this.$router.resolve({
      //   name: '/patentSearch/detail',
      //   query: {
      //     'id': paramId
      //   }
      // })
      // window.open(newpage.href, '_blank');

      window.open('#' + "/patentSearch/detail?id=" + paramId);
    },
    //生成随机字符串
    randomString (len) {
      len = len || 32;
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz";
      var maxLen = chars.length;
      var str = "";
      for (var i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxLen));
      }
      return str;
    },
    pdfEvent (pdfUrl, ucid) {
      var that = this,
        tokenRan = this.randomString(5);

      that.loadingBool(true);
      const hrefEl = document.createElement("a");
      hrefEl.href = pdfUrl + "?token=" + tokenRan;
      // hrefEl.target = "_blank";
      hrefEl.click();

      // 查看是否下载完成接口：?token=上面接口中传的token
      var timer = setInterval(() => {
        that.$axios
          .get(`/patent/pdf/progress`, {
            params: {
              token: tokenRan
            }
          })
          .then(res => {
            if (res.data.code == 0) {
              if (res.data.data === false) {
                that.loadingBool(false);
                clearInterval(timer);
              }
            }
          })
          .catch(err => {
            that.loadingBool(false);
            clearInterval(timer);
          });
      }, 500);
    }
  },
  watch: {
    dialogSwiperVisible (showData) {
      if (showData) {
        if (this.detailData && this.detailData.figureImages.length) {
          return this.detailData.figureImages.length;
        }
      }
    }
    // checkAggregations:{
    //   handler(newsName, oldName){
    //     if(newsName["lawState"]){
    //       if(newsName.includes('全部')){
    //         this.checkAggregations["lawState"] = ['invalid','valid','default']
    //       }else {
    //         // oldName.in
    //       }
    //     }
    //   },
    //   deep:true
    // }
  },
};
</script>

<style lang="less" scoped>
.list_wrap {
  .list_input_wrap {
    padding: 110px 0 20px 0;
    background: #fff;
    border: 1px solid #dbdee5;
    margin-bottom: 5px;
    position: fixed;
    width: 100%;
    z-index: 2;
    .input-single {
      width: 1065px;
      height: 46px;
      border-radius: 5px;
      border: solid 1px #c2c6d0;
      display: flex;
      margin: 0 auto;
      position: relative;
      span {
        // cursor: pointer;
        display: block;
        width: 65px;
        height: 46px;
        border-radius: 5px 0 0 5px;
        border-right: 1px solid #c2c6d0;
        background: #e1e7fb url("@{imgUrl}patent/global.png") no-repeat center;
      }
      .input_box {
        flex: 1;
        padding-left: 22px;
        padding-right: 110px;
        position: relative;
        .input-con {
          width: 100%;
          height: 44px;
          border: 0; // 去除未选中状态边框
          outline: none; // 去除选中状态边框
          background-color: rgba(0, 0, 0, 0); // 透明背景
          font-size: 14px;
        }
        .add_keywords_wrap {
          border-radius: 0;
          position: absolute;
          left: -1px;
          z-index: 1;
          width: 922px;
          padding: 30px 60px;
          border: solid 1px #c2c6d0;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          -o-box-sizing: border-box;
          box-sizing: border-box;
          background: #fff;
          .add_keywords_con {
            display: flex;
            margin-bottom: 16px;
            .del_hurdles_btn {
              display: block;
              width: 26px;
              background: #fff url("@{imgUrl}patent/del_icon.png") no-repeat
                left center/100% auto;
              cursor: pointer;
              margin-left: 19px;
              &:hover {
                background: #fff url("@{imgUrl}patent/del_icon_hover.png")
                  no-repeat left center/100% auto;
              }
            }
            .date_hurdles {
              margin-left: 11px;
              width: 35px;
            }
            &:first-child {
              .del_hurdles_btn {
                background: #fff;
                cursor: default;
              }
            }
          }
          .addHurdlesBtn {
            width: 190px;
            height: 34px;
            background: #eaecf1;
            border-radius: 5px;
            font-size: 14px;
            color: #515a71;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      .keyword_btn {
        width: 85px;
        line-height: 46px;
        color: #6c7383;
        text-align: center;
        cursor: pointer;
        position: relative;
        &::before {
          content: "";
          width: 1px;
          height: 24px;
          background: #c2c6d0;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -12px;
        }
      }
      .patent_switch {
        display: block;
        width: 60px;
        height: 26px;
        line-height: 24px;
        background-color: #8ba4fc;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        margin-top: -13px;
        right: 14px;
        font-size: 20px;
        color: #fff;
        padding: 0 10px;
        box-sizing: border-box;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          right: 1px;
          top: 1px;
          width: 24px;
          height: 24px;
          border-radius: 200px;
          background: #fff;
        }
        &.defaultActive {
          background: #e4e7f4;
          text-align: right;
          &::before {
            content: "";
            position: absolute;
            left: 1px;
            top: 1px;
            width: 24px;
            height: 24px;
            border-radius: 200px;
            background: #fff;
          }
        }
      }
      .patent_close {
        display: block;
        width: 18px;
        padding-right: 15px;
        position: absolute;
        right: 70px;
        top: 14px;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
        }
      }
      .query-btn {
        cursor: pointer;
        width: 80px;
        height: 48px;
        border-radius: 0 5px 5px 0;
        background: @blue url("@{imgUrl}common/search_icon.png") no-repeat
          center;
        margin-top: -1px;
        margin-right: -1px;
        &:hover {
          background: @blueHover url("@{imgUrl}common/search_icon.png")
            no-repeat center;
        }
      }
      &.borderBlue {
        border-color: @blue;
        span {
          border-color: @blue;
        }
        .input_box {
          .add_keywords_wrap {
            border-color: @blue;
          }
        }
        .keyword_btn {
          &::before {
            background: @blue;
          }
        }
      }
    }
  }
  .list_main {
    display: flex;
    padding-top: 170px;
    .list_middle {
      width: 100%;
      //   flex: 1;
      display: flex;
      .slide_left_btn {
        flex-grow: 0;
        flex-shrink: 0;
        width: 45px;
        padding-top: 16px;
        border-right: 1px solid #dbdee5;
        text-align: center;
        cursor: pointer;
      }
      .list_con {
        padding: 0 0 67px 28px;
        background: #fff;
        // width: 100%;
        .list_con_top {
          height: 70px;
          padding: 0 0px 0 30px;
          border-bottom: 1px solid #dbdee5;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .list_con_title {
            font-size: 18px;
            color: #333;
            span {
              font-size: 24px;
              color: @blue;
              font-weight: bold;
            }
          }
          ul {
            li {
              display: inline-block;
              padding: 0 13px;
              cursor: pointer;
              &:nth-child(1) {
                margin: 0 13px;
                height: 24px;
                vertical-align: -1px;
                background: url("@{imgUrl}patent/xls_report.png") no-repeat
                  center 1px / auto 100%;
                &:hover {
                  background: url("@{imgUrl}patent/xls_report_active.png")
                    no-repeat center 1px / auto 100%;
                }
              }
              &:nth-child(2) {
                width: 25px;
              }
              &:nth-child(3) {
                width: 19px;
              }
              &:nth-child(4) {
                width: 19px;
              }
              img {
                display: block;
                width: 100%;
              }
              &.active {
                cursor: default;
              }
            }
          }
        }
        .list_con_middle {
          height: 100%;
          height: calc(100vh - 325px);
          .list_con_middle_fixed {
            height: calc(100vh - 340px);
            overflow: auto;
            .no-data {
              text-align: center;
              margin-top: 100px;
              color: #586177;
            }
            .no-result {
              display: block;
              height: 200px;
              font-size: 0px;
              text-align: center;
              background: #fff;
              font-size: 15px;
              color: #949aa9;
              padding-top: 80px;
              i {
                display: block;
                width: 80px;
                height: 80px;
                margin: 30px auto 40px;
                img {
                  width: 100%;
                }
              }
            }
            .list_con_select_all {
              margin-top: 20px;
            }
            ul {
              li {
                display: flex;
                padding: 40px 0 40px 30px;
                border-bottom: 1px solid #e4e4e4;
                .img {
                  flex-grow: 0;
                  flex-shrink: 0;
                  width: 240px;
                  height: 240px;
                  // 全选 功能 先不做 margin-left:26px; 先注释
                  margin-right: 30px;
                  cursor: pointer;
                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                }
                .des {
                  .des_title {
                    width: 100%;
                    position: relative;
                    cursor: pointer;
                    h6 {
                      font-size: 18px;
                      color: #2a303f;
                      display: inline-block;
                      /deep/em {
                        color: #ff9f2d;
                      }
                    }
                    b {
                      display: inline-block;
                      height: 25px;
                      line-height: 24px;
                      padding: 0px 14px;
                      border-radius: 4px;
                      color: #fff;
                      margin-left: 12px;
                      &.effective {
                        background-color: #aed0fa;
                      }
                      &.invalid {
                        background-color: #d7d4d4;
                      }
                      &.trial {
                        background-color: #fae0ae;
                      }
                      &.unknown {
                        background-color: #ddbaf9;
                      }
                    }
                    dl {
                      position: absolute;
                      right: 0;
                      top: 50%;
                      margin-top: -13px;
                      dt {
                        display: inline-block;
                        width: 64px;
                        height: 6px;
                        border-radius: 3px;
                        &.high {
                          background-color: #ff5a5a;
                        }
                        &.middle {
                          background-color: #ffc742;
                        }
                        &.low {
                          background-color: #9bd53c;
                        }
                      }
                      dd {
                        display: inline-block;
                        font-size: 14px;
                        margin-left: 12px;
                        vertical-align: middle;
                        &.high {
                          color: #ff5a5a;
                        }
                        &.middle {
                          color: #ffc742;
                        }
                        &.low {
                          color: #9bd53c;
                        }
                      }
                    }
                  }
                  .des_info {
                    font-size: 14px;
                    margin: 10px 0;
                    span {
                      display: inline-block;
                      color: #999999;
                      margin-right: 30px;
                      line-height: 25px;
                      strong {
                        color: #333;
                        margin-right: 5px;
                      }
                      &:nth-child(1) {
                        strong {
                          color: @blue;
                          font-weight: bold;
                          text-decoration: underline;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                  .des_con {
                    font-size: 14px;
                    color: #454545;
                    line-height: 25px;
                    margin: 10px 0;
                    // /deep/em {
                    //   color: #ff9f2d;
                    // }
                  }
                  .fraction {
                    color: #9296a0;
                    font-size: 14px;
                    .rate {
                      display: inline-block;
                      vertical-align: text-top;
                    //   margin-left: 20px;
                    }
                    span {
                      margin-right: 10px;
                      color: @blue;
                    }
                  }
                }
                &:hover {
                  background: #f5f7fa;
                }
                &.active {
                  background: #f5f7fa;
                }
              }
            }
            &.flex {
              ul {
                li {
                  display: inherit;
                  .img {
                    display: block;
                    margin-bottom: 20px;
                  }
                }
              }
            }
            .list_table {
              i {
                cursor: pointer;
                img {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
          .list_con_bottom {
            text-align: center;
            margin-top: 40px;
          }
        }
      }
    }
    .list_left {
      width: 272px;
      height: calc(100vh - 173px);
      padding: 0 2px 0 5px;
      border-right: 1px solid #dbdee5;
      position: relative;
      .list_left_rela {
        height: calc(100vh - 253px);
        overflow: auto;
        .list_left_arrow {
          height: 70px;
          line-height: 70px;
          text-align: right;
          cursor: pointer;
          color: #515a71;
        }
        .keyword {
          padding: 0 20px 23px 26px;
          border-bottom: 1px solid #cfd4de;
          h6 {
            font-size: 14px;
            color: #586177;
            position: relative;
            &::before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 200px;
              background: @blue;
              position: absolute;
              left: -12px;
              top: 50%;
              margin-top: -4px;
            }
          }
          p {
            width: 100%;
            height: 34px;
            background-color: #ffffff;
            border-radius: 5px;
            // border: solid 1px #c2c6d0;
            position: relative;
            margin: 12px 0 20px;
            input {
              width: 130px;
              height: 32px;
              border-radius: 5px;
              padding-left: 10px;
              border: 0; // 去除未选中状态边框
              outline: none; // 去除选中状态边框
              background-color: rgba(0, 0, 0, 0); // 透明背景
            }
            i {
              cursor: pointer;
              display: block;
              width: 40px;
              height: 40px;
              position: absolute;
              right: 0;
              top: 0;
              img {
                position: absolute;
                display: block;
                width: 18px;
                top: 50%;
                margin-top: -9px;
                right: 14px;
              }
            }
          }
          // .el-tag-full {
          //   width: 100%;
          //   display: flex;
          //   justify-content: space-between;
          //   align-items: center;
          // }

          .el-tag {
            margin: 0 10px 10px 0;
            background-color: @blue;
            color: #fff;
            font-size: 14px;
            height: 28px;
            line-height: 24px;
          }
          // .el-tag-full + .el-tag {
          //   margin-left: 0;
          // }
          .no-data {
            color: #586177;
          }
          a {
            cursor: pointer;
            display: block;
            font-size: 12px;
            margin-top: 10px;
          }
        }

        .list_left_item {
          padding-left: 26px;
          border-bottom: 1px solid #cfd4de;
          h6 {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #586177;
            position: relative;
            cursor: pointer;
            &::before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 200px;
              background: @blue;
              position: absolute;
              left: -12px;
              top: 50%;
              margin-top: -4px;
            }
            i {
              display: block;
              width: 14px;
              height: 8px;
              position: absolute;
              right: 7px;
              top: 50%;
              margin-top: -4px;
              background: url("@{imgUrl}patent/down_icon.png") no-repeat center;
              -webkit-transition: -webkit-transform 0.3s ease;
              -moz-transition: -moz-transform 0.3s ease;
              transition: transform 0.3s ease;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              transform: rotate(0deg);
              &.rotate {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
          ul {
            li {
              margin-bottom: 14px;
            }
          }
          a {
            cursor: pointer;
            display: block;
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
          .list_left_child {
            border-top: 1px solid #cfd4de;
            h6 {
              &::before {
                background: transparent;
              }
            }
          }
        }
      }
    }
    button {
      display: block;
      width: 90%;
      height: 38px;
      text-align: center;
      line-height: 38px;
      background-color: @blue;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      margin: 20px auto 0;
      padding: 0;
      border: 1px solid transparent; //自定义边框
      outline: none; //消除默认点击蓝色边框效果
      &:hover {
        box-shadow: @boxShadow;
      }
    }
    .list_right {
      width: 50%;
      background: #fff;
      border-left: 1px solid #dbdee5;
      .list_right_top {
        height: 70px;
        padding: 0 50px 0 20px;
        border-bottom: 1px solid #dbdee5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          display: flex;
        }
        .left_btn {
          cursor: pointer;
          display: block;
          width: 13px;
          height: 23px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
          img {
            display: block;
            width: 100%;
          }
        }
        .pdf_btn {
          margin-left: 26px;
          cursor: pointer;
        }
        .enlarge_btn {
          cursor: pointer;
          display: block;
          width: 19px;
          height: 19px;
          img {
            display: block;
            width: 100%;
          }
        }
      }
      .list_rigth_title {
        padding-left: 35px;
        padding-top: 23px;
        strong {
          font-size: 16px;
          color: @blue;
        }
        span {
          display: inline-block;
          height: 25px;
          line-height: 24px;
          padding: 0px 14px;
          border-radius: 4px;
          color: #fff;
          margin-left: 12px;
          &.effective {
            background-color: #aed0fa;
          }
          &.invalid {
            background-color: #d7d4d4;
          }
          &.trial {
            background-color: #fae0ae;
          }
          &.unknown {
            background-color: #ddbaf9;
          }
        }
        h6 {
          display: block;
          font-size: 18px;
          color: #2a303f;
          margin: 24px 0 34px;
          /deep/em {
            color: #ff9f2d;
          }
        }
      }
      .list_right_tab {
        border-bottom: 1px solid #dbdee5;
        li {
          display: inline-block;
          color: #9296a0;
          font-size: 16px;
          cursor: pointer;
          padding-bottom: 8px;
          margin: 0 30px;
          &:hover {
            color: @blue;
          }
          &.active {
            color: @blue;
            border-bottom: 3px solid @blue;
          }
        }
      }
      .list_right_tab_con {

        padding: 0 26px 26px 35px;
        height: calc(100vh - 452px);
        margin-top: 26px;
        overflow: auto;
        box-sizing: border-box;
        li {
          font-size: 14px;
          line-height: 25px;
          color: #333;
          h6 {
            color: @blue;
            margin: 22px 0 6px;
          }
          p {
            display: inline-block;
            span {
              color: #999;
              display: inline-block;
              margin-right: 30px;
              strong {
                color: #333;
                margin-right: 5px;
              }
            }
            .error {
              display: none;
            }
            img {
              cursor: pointer;
              display: inline-block;
              width: 245px;
              height: 176px;
              margin: 10px;
            }
          }
        }
        &.pd10 {
          padding-left: 10px;
          h5 {
            color: #6eabf6;
          }
        }
      }
    }

    .slide-left-enter-active {
      transition: all 0.2s ease-in;
    }
    .slide-left-leave-active {
      transition: all 0.2s ease-out;
    }
    .slide-left-enter,
    .slide-left-leave-to {
      transform: translateX(-300px);
      opacity: 0;
    }

    .slide-right-enter-active {
      transition: all 0.2s ease-in;
    }
    .slide-right-leave-active {
      transition: all 0.2s ease-out;
    }
    .slide-right-enter,
    .slide-right-leave-to {
      transform: translateX(50%);
      opacity: 0;
    }
  }
}
@media screen and (max-width: 1680px) {
  .list_wrap {
    .list_main {
      .list_middle {
        .list_con {
          padding: 0 0 67px 0;
          .list_con_middle {
            .list_con_middle_fixed {
              ul {
                li {
                  &.li1680 {
                    display: inherit;
                    .img {
                      display: block;
                      margin-bottom: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .list_right {
        .list_right_tab {
          min-width: 440px;
        }
      }
    }
  }
}
.swiper_dialog {
  .swiper-container {
    background: transparent;
    .swiper-slide {
      padding: 40px;
      border-radius: 3px;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
  }
  .swiper_right {
    height: 500px;
    .swiper-slide {
      width: 190px;
      height: 170px;
      border-radius: 3px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<style lang="less">
.list_wrap .el-tag .el-tag__close {
  color: #fff;
}

@media screen and (max-width: 1366px) {
  .list_wrap {
    .el-pager li {
      min-width: 20px;
    }
    .el-pagination__jump {
      margin-left: 0;
    }
    .el-pagination__sizes {
      margin: 0;
    }
    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      min-width: 20px;
    }
  }
}
.list_wrap {
  .add_keywords_wrap {
    .add_keywords_select {
      margin-right: 20px;
      .el-input__inner {
        width: 190px;
        height: 34px;
        line-height: 34px;
      }
    }
    .add_keywords_input_wrap {
      width: 335px;
      position: relative;
      .add_keywords_input {
        height: 34px;
        .el-input__inner {
          height: 34px;
          line-height: 34px;
          box-sizing: border-box;
        }
      }
      .add_keywords_title {
        .el-input__inner {
          padding: 0 50px 0 15px;
          box-sizing: border-box;
        }
        .el-input__suffix {
          right: 30px;
        }
      }
      .search_keyword_btn {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 10px;
        display: block;
        width: 16px;
        height: 16px;
        background: #fff url("@{imgUrl}patent/icon_keyword_default.png")
          no-repeat left center/100% auto;
        cursor: pointer;
        &:hover {
          background: #fff url("@{imgUrl}patent/icon_keyword_active.png")
            no-repeat left center/100% auto;
        }
      }
    }
    .add_keywords_date {
      .el-input__inner {
        width: 156px;
        height: 34px;
        line-height: 34px;
      }
    }
  }
  .el-table__empty-block {
    display: none;
  }
}
.el-table .selected-row {
  background: #f5f7fa;
}
.swiper_dialog {
  background: transparent;
  .el-dialog__header {
    display: none;
  }
  .swiper-wrapper {
    min-height: 600px;
    align-items: center;
  }
}
</style>
